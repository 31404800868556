@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayThin.ttf") format("truetype");
}

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayLight.ttf") format("truetype");
}

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayRoman.ttf") format("truetype");
}

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayMediu.ttf") format("truetype");
}

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayBold.ttf") format("truetype");
}

@font-face {
  font-family: "Neue";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/public/fonts/NeueHaasDisplayBlack.ttf") format("truetype");
}

* {
  font-family: "Neue", sans-serif;
}

html {
  background: #000;
  color: white;
  line-height: 1.5;
  tab-size: 4;
}

body {
  font-family: inherit;
}

html,
body,
#__next {
  min-height: 100%;
  margin: 0px;
  background-repeat: no-repeat;
}

#root {
  overflow: hidden;
}

:root {
  --app-height: 100%;
}

.checkout,
.pioneer {
  height: 100vh;
  height: var(--app-height);
}

.pioneer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

@media only screen and (min-width: 993px) {

  body,
  html {
    max-width: 100%;
  }
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

button {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  caret-color: white !important;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
}

button:disabled {
  /* opacity: 0.5; */
  cursor: not-allowed !important;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

img {
  border: 0;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input, textarea {
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
}

a {
  outline: none;
}

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2 {
  line-height: 110%;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

button:disabled {
  background-image: unset;
}

a:hover, a:disabled {
  background-image: unset;
}

@keyframes spinner-mobile {
  from {
    stroke-dashoffset: 570;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.rings-mobile, .rings-mobile>svg, .rings-mobile>circle {
  width: 192px;
  height: 192px;
}

.rings-mobile circle {
  position: absolute;
  fill: none;
  stroke-width: 7;
  transform: translate(7px, 7px);
  stroke-dasharray: 570;
  stroke-linecap: round;
}

.rings-mobile circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: rgba(21,21,21,0.5);
}

.rings-mobile circle:nth-child(2) {
  stroke-dashoffset: 0;
  animation: spinner-mobile 4s linear;
}

@keyframes spinner {
  from {
    stroke-dashoffset: 440;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.rings, .rings>svg, .rings>circle {
  width: 136px;
  height: 136px;
}

.rings circle {
  position: absolute;
  fill: none;
  stroke-width: 5;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-linecap: round;
}

.rings circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: rgba(21,21,21,0.5);
}

.rings circle:nth-child(2) {
  stroke-dashoffset: 0;
  animation: spinner 4s linear;
}

.man-container {
  clip-path: polygon(0% 0%, 100% 0%, 100% 78%, 0 100%);
}

@media (max-width: 360px) {
  .demo-btn {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .loop-text {
    font-size: 26px !important;
  }
}

@media (max-width: 1024px) {
  .footergrid {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 500px) {
  .footergrid {
    display: grid !important;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}